<template>
  <b-row no-gutters>
    <b-col cols="12" class="border-bottom">
      <h5 class="font-weight-bold p-2 mb-0 text-center bg-light">
        {{ subject }}
      </h5>
    </b-col>
    <b-col cols="12" class="p-2">
      <!-- INTRO -->
      <intro
        class="mb-2"
        :name="client.firstName"
        :text="$t('emails.clientBookingCancellation.intro', locale)"
      />

      <!-- ALERT -->
      <alert
        class="mb-2"
        variant="secondary"
        :text="$t('emails.clientBookingCancellation.alert', locale)"
      />

      <!-- PICTURE && ACCOMMODATION INFO -->
      <accommodation-info
        :accommodation="accommodation"
        booking-status="CANCELLED"
        :locale="locale"
      />

      <b-divider size="lg" />

      <!-- LOCALIZATOR -->
      <localizator :locale="locale" />

      <b-divider size="lg" />

      <!-- CHECK-IN AND CHECKOUT TIMES -->
      <checkin-checkout
        :checkin="checkin"
        :checkout="checkout"
        :locale="locale"
        hide-times
      />

      <b-divider size="lg" />

      <!-- INTRANET BUTTON -->
      <b-button block variant="primary" target="blank" :href="intranetLink">
        {{ $t("emails.common.intranetAccessButton", locale) }}
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BButton } from "bootstrap-vue";
import { getAccommodationTypeName } from "@/utils/methods";
import { formatDateStringToDate } from "@/utils/formatters";
import BDivider from "@foravila-core/components/b-divider/BDivider.vue";
import Intro from "@/views/bookings/booking/components/mail/Intro.vue";
import Alert from "@/views/bookings/booking/components/mail/Alert.vue";
import AccommodationInfo from "@/views/bookings/booking/components/mail/AccommodationInfo.vue";
import Localizator from "@/views/bookings/booking/components/mail/Localizator.vue";
import CheckinCheckout from "@/views/bookings/booking/components/mail/CheckinCheckout.vue";
import {
  AGENCY_GUESTS_INTRANET_LOGIN,
  AGENCY_GUESTS_INTRANET_REGISTER,
} from "@appConfig";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BDivider,
    Intro,
    Alert,
    AccommodationInfo,
    Localizator,
    CheckinCheckout,
  },
  props: {
    locale: {
      type: String,
      default: "en-GB",
    },
  },
  data() {
    return {
      content: {},
    };
  },
  computed: {
    accommodation() {
      return this.$store.getters["accommodation/accommodation"];
    },
    accommodationPicture() {
      return this.accommodation.coverPicture || null;
    },
    accommodationType() {
      if (!this.accommodation) return null;
      return getAccommodationTypeName(this.accommodation.type, this.locale);
    },
    accommodationLocation() {
      if (!this.accommodation?.location) return null;
      const location = [];
      if (this.accommodation.location.city)
        location.push(this.accommodation.location.city);
      if (this.accommodation.location.region)
        location.push(this.accommodation.location.region);
      if (!location.length) return null;
      return location.join(", ");
    },
    booking() {
      return this.$store.getters["booking/booking"];
    },
    checkin() {
      const formatting = {
        weekday: "long",
        day: "numeric",
        month: "long",
        year: "numeric",
      };
      return formatDateStringToDate(
        this.booking.checkin,
        this.locale,
        formatting
      );
    },
    checkout() {
      const formatting = {
        weekday: "long",
        day: "numeric",
        month: "long",
        year: "numeric",
      };
      return formatDateStringToDate(
        this.booking.checkout,
        this.locale,
        formatting
      );
    },
    client() {
      return this.$store.getters["booking/client"];
    },
    subject() {
      return `${this.$t(
        "emails.clientBookingCancellation.subject",
        this.locale,
        {
          localizator: this.booking?.localizator || null,
          accommodationName: this.accommodation?.name || null,
        }
      )}`;
    },
    intranetLink() {
      if (!this.client) return null;
      if (!this.client.user)
        return `${AGENCY_GUESTS_INTRANET_REGISTER}/${this.client.uuid}?l=${this.locale}`;
      return `${AGENCY_GUESTS_INTRANET_LOGIN}?l=${this.locale}`;
    },
  },
  watch: {
    content: {
      deep: true,
      handler() {
        this.$emit("content-updated", this.content);
      },
    },
  },
  created() {
    this.initContent();
  },
  methods: {
    initContent() {
      this.content = {
        locale: this.locale,
        subject: this.subject,
        clientName: this.client?.firstName || null,
        clientEmail: this.client?.email || null,
        introHello: `Hola ${this.client?.firstName || null}`,
        introText: this.$t(
          "emails.clientBookingCancellation.intro",
          this.locale
        ),
        introAlertText: this.$t(
          "emails.clientBookingCancellation.alert",
          this.locale
        ),
        accommodationPicture: this.accommodationPicture?.url || null,
        accommodationDescription: this.accommodationType,
        accommodationName: this.accommodation?.name || null,
        accommodationLocation: this.accommodationLocation,
        localizator: this.booking.localizator,
        checkin: this.checkin,
        checkout: this.checkout,
        intranetLink: this.intranetLink,
      };
    },
  },
};
</script>
